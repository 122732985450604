import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { StorageAPILoader } from 'src/app/ajs-upgraded-providers';
import { StorageUtilsService } from './storage-utils.service';
import { FileMetadataUtilsService } from 'src/app/template-editor/template-components/services/file-metadata-utils.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

@Injectable({
  providedIn: 'root'
})
export class FileCheckerService {

  private cachedFiles: any = {};

  constructor(
    private broadcasterService: BroadcasterService,
    private storageAPILoader: StorageAPILoader,
    private fileMetadataUtilsService: FileMetadataUtilsService,
    private storageUtils: StorageUtilsService
  ) { }

  _requestFileData(companyId, file) {
    const search = {
      'companyId': companyId,
      'file': file
    };

    return this.storageAPILoader()
      .then((storageApi) => {
        return storageApi.files.get(search);
      });
  }

  _getFile(fileName) {
    const match = this.storageUtils.parseFilePath(fileName);

    if (!match) {
      return Promise.resolve(null);
    }

    return this._requestFileData(match[1], match[2])
      .then( (resp) => {
        var file = resp && resp.result && resp.result.result &&
          resp.result.files && resp.result.files[0];

        if (!file) {
          return null;
        }

        return file;
      })
      .catch( (error) => {
        console.error(error);

        return null;
      });
  }

  requestFiles(files: string[]): Promise<any> {
    const promises = _.map(files, (fileName) => {
      if (this.cachedFiles[fileName]) {
        return Promise.resolve(this.cachedFiles[fileName]);
      }

      return this._getFile(fileName)
        .then( (file) => {

          this.cachedFiles[fileName] = file;

          return file;
        });
    });

    return Promise.all(promises).then((results) => {
      const files = [];

      _.reject(results, _.isNil).forEach( (file) => {
        files.push(file);
      });

      return files;
    })
    .finally(() => {
      this.broadcasterService.emit('risevision.template-editor.storageCacheLoaded');
    });
  }

  setCachedFile(file) {
    const fileName = this.fileMetadataUtilsService.getFilePath(file);

    this.cachedFiles[fileName] = file;

    this.broadcasterService.emit('risevision.template-editor.storageCacheLoaded');
  }

  getCachedFile(fileName) {
    return this.cachedFiles[fileName];
  }

}
