import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as angular from 'angular';

@Injectable({
  providedIn: 'root'
})
export class TemplateEditorUtilsService {

  constructor() { }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  intValueFor(providedValue, defaultValue?) {
    var intValue = parseInt(providedValue, 10);

    return isNaN(intValue) ? defaultValue : intValue;
  }

  floatValueFor(providedValue, defaultValue?) {
    var floatValue = parseFloat(providedValue);

    return isNaN(floatValue) ? defaultValue : floatValue;
  }

  addOrRemove(list, oldItem, newItem) {
    var idx = _.findIndex(list, oldItem);

    if (idx >= 0) {
      list.splice(idx, 1);
    } else {
      list.push(newItem);
    }

    return list;
  }

  addOrReplaceAll(list, oldItem, newItem) {
    var matchCount = 0;

    for (var i = 0; i < list.length; i++) {
      var item = list[i];

      if (_.isMatch(item, oldItem)) {
        matchCount++;
        list.splice(i, 1, newItem);
      }
    }

    if (matchCount === 0) {
      list.push(newItem);
    }
  }

  isFolder(path) {
    return path[path.length - 1] === '/';
  }

  parseFolderName (path: string) {
    if (!path) return '';
    path = path.trim().replace(/\/+$/, ''); // Remove trailing slashes
    let slash = path.lastIndexOf('/');
    return slash < 0 ? path : path.substring(slash + 1);
  }

  parseStoragePath (path: string) {
    if (!path) return '';
    if (path.indexOf('risemedialibrary') === 0) {
      let start = path.indexOf('/');
      return start < 0 ? '/' : path.substring(start);
    }
    return path;
  }

  getUploadedFolder(name, rootFolder?) {
    if (!name) return '';
    if (name.indexOf(rootFolder) === -1) return name;

    // Get first slash after rootFolder path
    let firstSlash = name.indexOf('/', rootFolder.length);

    return name.substring(0, firstSlash + 1);
  }

  isStaging() {
    try {
      var hostname = window.location.hostname;

      return hostname.includes('apps-stage-');
    } catch (err) {
      console.log('can\'t access hostname of window.location');
    }

    return false;
  }

  fileNameOf(path) {
    var parts = path.split('/');

    if (this.isFolder(path)) {
      return parts[parts.length - 2];
    } else {
      return parts.pop();
    }
  }

  hasRegularFileItems(folderItems, includeFolders?) {
    var regularFiles = _.filter(folderItems, item => {
      return includeFolders || !this.isFolder(item.name);
    });

    return regularFiles.length > 0;
  }

  findElement(selector, parent?) {
    if (parent) {
      return parent.find(selector);
    } else {
      return document.querySelector(selector) && angular.element(document.querySelector(selector));
    }
  }

  repeat(value, times) {
    var items = [];

    for (var i = 0; i < times; i++) {
      items.push(value);
    }

    return items;
  }

  padNumber(number, minLength) {
    var numberStr = String(number);
    var numberLen = numberStr.length || 0;

    if (numberLen < minLength) {
      return this.repeat('0', minLength - numberLen).join('') + numberStr;
    } else {
      return numberStr;
    }
  }

  formatISODate(dateString) {
    var date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return null;
    } else {
      return this.padNumber(date.getUTCFullYear(), 4) + '-' + this.padNumber(date.getUTCMonth() + 1, 2) + '-' +
        this.padNumber(date.getUTCDate(), 2);
    }
  }

}